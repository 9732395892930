import React, { useContext, useEffect, useState } from 'react'
import Title from '.././title'
import { ScrollBoard } from '@jiaminghi/data-view-react'
import useWindowDimensions from '../../hook/window'
import { DataContext } from '../../../App'

export default props => {
  const { height, width } = useWindowDimensions()

  let data = useContext(DataContext)
  let final_data = []
  if (data && data.batches_change_record && Array.isArray(data.batches_change_record)) {
    final_data = data.batches_change_record.map(function(item) {
      // 倒发
      if (Number(new Date(item.validity)) < Number(new Date(item.past_record__validity))) {
        return [
          `<span class="check-tag">${item.product__name}</span>`,
          `<span class="check-tag">${item.product__brand_name}</span>`,
          `<span class="check-tag">${item.product__spec_name}</span>`,
          `<span class="check-tag">${item.batchnum}</span>`,
          `<span class="check-container" style="height: ${0.015 * height +
            10}px; line-height: ${0.015 * height + 10}px">倒发</span>`,
          `<span class="check-tag">${item.product__lab_name}</span>`,
        ]
      }

      return [
        item.product__name,
        item.product__brand_name,
        item.product__spec_name,
        item.batchnum,
        '',
        item.product__lab_name,
      ]
    })
  }

  let config = {
    header: ['物资名称', '厂商', '规格', '批号', '', '实验室'],
    data: final_data,
    index: true,
    indexHeader: '序号',
    columnWidth: [
      width * 0.05,
      width * 0.17,
      width * 0.07,
      width * 0.08,
      width * 0.07,
      width * 0.05,
      width * 0.11,
    ],
    align: ['center', 'center', 'center', 'center'],
    rowNum: 4,
    headerHeight: height * 0.025,
    headerBGC: 'transparent',
    oddRowBGC: 'rgba(0, 44, 81, 0.8)',
    evenRowBGC: 'rgba(10, 29, 50, 0.8)',
  }
  return (
    <div className={'batch-table'}>
      <Title title={'新批号到货提醒'} image={'/img/10/expire.png'} />
      {final_data.length !== 0 ? (
        <div className={'right-alert'}>
          <span className={'font-ten'} style={{ color: '#FFBF00' }}>
            {final_data.length} 个品项新批号到货，请做好标记并提醒老师
          </span>
        </div>
      ) : (
        <></>
      )}
      <div
        style={{
          marginLeft: 0.03 * width,
          height: 0.185 * height,
          width: 0.45 * width,
        }}
        className={'table-container'}
      >
        <ScrollBoard config={config}></ScrollBoard>
      </div>
    </div>
  )
}
