import React, { useContext, useEffect, useState } from 'react'
import Title from '.././title'
import { ScrollBoard } from '@jiaminghi/data-view-react'
import useWindowDimensions from '../../hook/window'
import { DataContext } from '../../../App'

export default props => {
  const { height, width } = useWindowDimensions()

  let data = useContext(DataContext)
  let final_data = []
  if (
    data &&
    data.overdue_orders &&
    data.overdue_orders.results &&
    Array.isArray(data.overdue_orders.results)
  ) {
    final_data = data.overdue_orders.results.map(function(item) {
      return [
        item.product_name,
        item.brand_name,
        item.product__spec_name,
        item.quantity - item.progress,
        item.order__code,
      ]
    })
  }

  let config = {
    header: ['物资名称', '厂商', '规格', '欠货数量', '订单号'],
    data: final_data,
    index: true,
    indexHeader: '序号',
    columnWidth: [
      width * 0.05,
      width * 0.17,
      width * 0.07,
      width * 0.08,
      width * 0.08,
      width * 0.11,
    ],
    align: ['center', 'center', 'center', 'center'],
    rowNum: 4,
    headerHeight: height * 0.025,
    headerBGC: 'transparent',
    oddRowBGC: 'rgba(0, 44, 81, 0.8)',
    evenRowBGC: 'rgba(10, 29, 50, 0.8)',
  }
  return (
    <div>
      <Title title={'订单收货异常'} image={'/img/10/expire.png'} />
      {final_data.length !== 0 ? (
        <div className={'right-alert'}>
          <span className={'font-ten'} style={{ color: '#FFBF00' }}>
            {final_data.length} 个品项超过3周未到货，请注意跟踪订单情况
          </span>
        </div>
      ) : (
        <></>
      )}
      <div
        style={{
          marginLeft: 0.03 * width,
          height: 0.185 * height,
          width: 0.45 * width,
        }}
        className={'table-container'}
      >
        <ScrollBoard config={config}></ScrollBoard>
      </div>
    </div>
  )
}
