import React, { useContext, useEffect, useState } from 'react'
import Title from '.././title'
import { ScrollBoard } from '@jiaminghi/data-view-react'
import useWindowDimensions from '../../hook/window'
import { DataContext } from '../../../App'

export default props => {
  const { height, width } = useWindowDimensions()

  let data = useContext(DataContext)
  let final_data = []
  if (data && data.abnormal_operations && Array.isArray(data.abnormal_operations)) {
    final_data = data.abnormal_operations.map(function(item) {
      let output = [
        item.product__name,
        item.product__brand_name,
        item.product__spec_name,
        item.target__name === null ? item.origin__name : item.target__name,
        '',
      ]
      if (item.operation_type === 'YK9') {
        output[4] = `<div class="reason-container" data-type="YK9" style="height: ${0.03 *
          height}px; line-height: ${0.03 * height}px">退库异常</div>`
      }
      if (item.operation_type === 'YK4') {
        output[4] = `<div class="reason-container" data-type="YK4" style="height: ${0.03 *
          height}px; line-height: ${0.03 * height}px">在途库</div>`
      }
      if (item.operation_type === 'RK9') {
        output[4] = `<div class="reason-container" data-type="RK9" style="height: ${0.03 *
          height}px; line-height: ${0.03 * height}px">异常入库</div>`
      }
      return output
    })
  }

  let config = {
    header: ['物资名称', '厂商', '规格', '库位', '异常原因'],
    data: final_data,
    index: true,
    indexHeader: '序号',
    columnWidth: [
      width * 0.05,
      width * 0.17,
      width * 0.07,
      width * 0.08,
      width * 0.08,
      width * 0.07,
    ],
    align: ['center', 'center', 'center', 'center'],
    rowNum: 4,
    headerHeight: height * 0.025,
    headerBGC: 'transparent',
    oddRowBGC: 'rgba(0, 44, 81, 0.8)',
    evenRowBGC: 'rgba(10, 29, 50, 0.8)',
  }
  return (
    <div className={'abnormal-table'}>
      <Title title={'智能冰箱异常提醒'} image={'/img/10/expire.png'} />
      {final_data.length !== 0 ? (
        <div className={'right-alert'}>
          <span className={'font-ten'} style={{ color: '#FFBF00' }}>
            {final_data.length} 个品项补货异常，请现场复核
          </span>
        </div>
      ) : (
        <></>
      )}
      <div
        style={{
          marginLeft: 0.03 * width,
          height: 0.185 * height,
          width: 0.45 * width,
        }}
        className={'table-container'}
      >
        <ScrollBoard config={config}></ScrollBoard>
      </div>
    </div>
  )
}
