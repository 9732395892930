import React, { useContext, useEffect, useState } from 'react'
import Box from './box'
import Title from './title'
import { DataContext } from '../../App'
import useWindowDimensions from '../hook/window'
export default props => {
  let data = useContext(DataContext)
  let final = {}
  let right_count = {
    normal: 0,
    abnormal: 0,
  }
  const { height, width } = useWindowDimensions()
  if (data && data.temperature_list && Array.isArray(data.temperature_list)) {
    data.temperature_list.forEach(function(item) {
      let timeout_min = 20
      let curr = {
        name: item.name.replace('免疫组冰箱', ''),
        alert: Boolean(
          item.records.find((i, n) => {
            if (n === 0) {
              if (
                Number(data.request_time) - Number(new Date(i.update_datetime)) >=
                1000 * 60 * timeout_min
              ) {
                return true
              }
            }
            return i.status !== 0
          })
        ),
        timeout: Boolean(
          item.records.find((i, n) => {
            if (n === 0) {
              if (
                Number(data.request_time) - Number(new Date(i.update_datetime)) >=
                1000 * 60 * timeout_min
              ) {
                return true
              }
            }
            return false
          })
        ),
      }
      if (curr.alert) {
        right_count.abnormal += 1
      } else {
        right_count.normal += 1
      }
      if (final[item.iot_storehouse_name]) {
        final[item.iot_storehouse_name].push(curr)
      } else {
        final[item.iot_storehouse_name] = [curr]
      }
    })
  }

  let final_data = Object.keys(final).map(key => {
    final[key] = final[key].sort(function(a, b) {
      return a.name.localeCompare(b.name)
    })
    return {
      name: key,
      fridge_list: final[key],
    }
  })

  let gap = width * 0.01
  let remain = 0.9 * width - gap * (final_data.length - 1)
  let count = final_data.reduce(function(prev, curr) {
    return prev + (curr.fridge_list.length === 1 ? 1 : (curr.fridge_list.length - 1) * 0.5 + 1)
  }, 0)

  let unit_width = remain / count

  final_data = final_data.map(function(item) {
    if (item.fridge_list.length === 1) {
      item.width = unit_width
    } else {
      item.width = unit_width * ((item.fridge_list.length - 1) * 0.5 + 1)
    }
    item.fridge_list = item.fridge_list.map(function(item) {
      item.img = (
        <div key={item.name} className={'fridge-item'}>
          <div className={item.alert ? 'fridge-image fridge-image-alert' : 'fridge-image'}>
            <img src={item.alert ? '/img/10/fridge_abnormal.png' : '/img/10/fridge_normal.png'} />
            <div
              style={{
                top: item.timeout ? '0' : '5%',
              }}
            >
              {item.alert ? (
                <span className="temp-abnormal-text">{item.timeout ? '无记录' : '异常'}</span>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className={'fridge-name'}>
            {item.alert ? <span className="temp-abnormal-text">{item.name}</span> : item.name}
          </div>
        </div>
      )
      return item
    })
    return item
  })

  return (
    <>
      <div className={'chart-container'}>
        <div className={'line-container'}>
          <div></div>
        </div>
        <Title title={'冰箱温度异常预警'} image={'/img/10/fridge.png'} />
        <div className={'count-container'}>
          <div className={'count-title'}>
            温度正常：<span className={'count-count font-ten'}>{right_count.normal}</span>个
          </div>
          <div className={'count-title'}>
            温度异常：
            <span
              className={'count-count font-ten'}
              style={{
                color: '#FFBF00',
              }}
            >
              {right_count.abnormal}
            </span>
            个冰箱温度持续上升
          </div>
        </div>
        <div className={'chart-main'}>
          <div className={'fridge-list-container'}>
            {final_data.map(function(item) {
              return (
                <Box
                  height={0.15 * height}
                  width={item.width}
                  title={item.name}
                  content={
                    <div className={'fridge-item-container'}>
                      {item.fridge_list.map(i => i.img)}
                    </div>
                  }
                />
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
