import React, { useContext, useEffect, useState } from 'react'
import Title from '.././title'
import { ScrollBoard } from '@jiaminghi/data-view-react'
import useWindowDimensions from '../../hook/window'
import { DataContext } from '../../../App'
import * as echarts from 'echarts'

export default props => {
  const { height, width } = useWindowDimensions()
  let data = useContext(DataContext)
  let final_data = []

  if (data && data.validity_date_distribution && Array.isArray(data.validity_date_distribution)) {
    let sum = data.validity_date_distribution.reduce(function(prev, curr) {
      return prev + curr.count
    }, 0)

    data.validity_date_distribution.forEach(function(item) {
      if (item.stage === '365-') {
        final_data.push({
          itemStyle: { normal: { color: '#9239ff' } },
          value: item.count,
          index: 5,
          name: '一年以上' + '：' + Math.round((item.count / sum) * 100) + '%',
        })
      }
      if (item.stage === '180-365') {
        final_data.push({
          itemStyle: { normal: { color: '#3977FF' } },
          value: item.count,
          index: 4,
          name: '一年以内' + '：' + Math.round((item.count / sum) * 100) + '%',
        })
      }
      if (item.stage === '90-180') {
        final_data.push({
          itemStyle: { normal: { color: '#6BECFF' } },
          value: item.count,
          index: 3,
          name: '半年以内' + '：' + Math.round((item.count / sum) * 100) + '%',
        })
      }
      if (item.stage === '60-90') {
        final_data.push({
          itemStyle: { normal: { color: '#61C003' } },
          value: item.count,
          index: 2,
          name: '3个月内' + '：' + Math.round((item.count / sum) * 100) + '%',
        })
      }
      if (item.stage === '30-60') {
        final_data.push({
          itemStyle: { normal: { color: '#E3AB4E' } },
          value: item.count,
          index: 1,
          name: '2个月内' + '：' + Math.round((item.count / sum) * 100) + '%',
        })
      }
      if (item.stage === '-30') {
        final_data.push({
          itemStyle: { normal: { color: '#FF4834' } },
          value: item.count,
          index: 0,
          name: '1个月内' + '：' + Math.round((item.count / sum) * 100) + '%',
        })
      }
    })
  }

  final_data = final_data.sort(function(a, b) {
    return b.index - a.index
  })

  function render() {
    var chartDom = document.getElementById('pie-main')
    var myChart = echarts.init(chartDom)
    var option

    var colors = ['blue', 'red', 'yellow', 'green', 'purple']

    option = {
      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['80%', '90%'],
          itemStyle: {
            borderColor: 'black',
            borderWidth: 2,
          },
          tooltip: {
            show: false,
          },
          label: {
            color: 'white',
          },
          data: final_data,
        },
        {
          name: 'Access From',
          color: colors,
          type: 'pie',
          radius: ['10%', '77%'],
          itemStyle: {
            borderColor: 'black',
            borderWidth: 2,
          },
          label: {
            show: false,
          },
          tooltip: {
            show: false,
          },
          data: final_data.map(function(item) {
            let a = JSON.parse(JSON.stringify(item))
            a.itemStyle.normal.color += '55'
            return a
          }),
        },
      ],
    }

    option && myChart.setOption(option)
  }

  useEffect(() => {
    render()
  }, [height, width, final_data, render])

  return (
    <div>
      <Title title={'库存效期分布提醒'} image={'/img/10/expire.png'} />
      <div
        style={{
          height: 0.15 * height,
          width: 0.25 * width,
          position: 'relative',
        }}
      >
        <div
          id="pie-main"
          style={{
            height: 0.15 * height,
            width: 0.25 * width,
          }}
        ></div>
        <div
          style={{
            color: 'white',
            position: 'absolute',
            top: '0',
            textAlign: 'center',
            lineHeight: 0.15 * height + 'px',
            width: 0.25 * width,
          }}
        >
          效期分布
        </div>
        <div
          style={{
            color: 'white',
            position: 'absolute',
            top: '0',
            left: 0.25 * width,
            width: 0.2 * width,
          }}
          className={'valid-distribution-container'}
        >
          {final_data &&
            final_data.map(function(item) {
              return (
                <div className={'valid-distribution'}>
                  <div
                    className={'valid-distribution-color'}
                    style={{
                      background: `linear-gradient(90deg, ${item.itemStyle.normal.color} 70%, ${item.itemStyle.normal.color} 70%, ${item.itemStyle.normal.color} 100%)`,
                    }}
                  ></div>
                  {item.name}
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
